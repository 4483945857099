import React from 'react'
import Layout from 'elements/Frame/Layout'
import Title from 'elements/Typography/Title'
import BackButton from 'elements/Button/BackButton'
import { Text, Image } from '@chakra-ui/core'
import lilbuddy from 'images/404.png'

export default function NotFoundPage () {
  return (
    <Layout>

      <Title>Not found</Title>
      <Text>
      Oops! Looks like the page you requested doesn&apos;t exist. Or we ate it.
      Either way it&apos;s not here any more. Sad panda...
      </Text>
      <Image textAlign="center" mt="3" size="150px" src={lilbuddy}/>
      <BackButton />
    </Layout>)
}
