import React, { forwardRef } from 'react'
import { Button } from '@chakra-ui/core'

const BackButton = forwardRef((props, ref) => {
  return (
    <Button
      mt={4}
      variant="solid"
      leftIcon="arrow-back"
      onClick={e => {
        e.preventDefault()
        history.back()
      }}
      {...props}
      ref={ref}
    >
      Go Back
    </Button>
  )
})
BackButton.displayName = 'BackButton'

export default BackButton
